<template>
  <div class="info">
    <div class="info_banner">
      <div class="imgBox">
        <img :src="initData.apparatusFileId" />
      </div>

      <div class="banner_text">
        <div class="mask"></div>
        <div class="textCntanier">
          <div class="title">{{ initData.apparatusType }}</div>
          <div class="text">{{ initData.apparatusName }}</div>
          <div class="box">
            <div class="box_text">
              <div v-for="(i, index) in initData.apparatusTag" :key="index">
                {{ i }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="floatbanner">
        <div class="floatTile">
          {{ initData.apparatusType }}{{ initData.apparatusName }}
        </div>
        <div class="floatTable">
          <div class="tabletitle title">厂家： {{ initData.firmName }}</div>
          <div class="tabletitle col">
            <div class="score">
              <div class="score_num">{{ initData.apparatusScore }}</div>
              <div class="score_text">综合评分</div>
            </div>
          </div>
        </div>
        <div class="button">
          <el-button
            type="primary"
            round
            @click="to('/instrumentlib/contrast', 1)"
          >
            参数对比
          </el-button>
          <el-button round @click="to('/instrumentlib/comcontrast', 2)">
            点评对比
          </el-button>
        </div>
      </div>
    </div>
    <div class="info_content">
      <div class="info_content-tabs">
        <!-- <el-menu mode="horizontal">
          <el-menu-item>器械详情</el-menu-item>
          <el-menu-item>参数列表</el-menu-item>
          <el-menu-item>点评</el-menu-item>
          <el-menu-item>推荐</el-menu-item>
        </el-menu> -->
        <div class="info_content-menus">
          <div class="border">
            <div class="menus">
              <div
                class="item"
                :class="{ click: item.isClick }"
                v-for="(item, index) in menus"
                :key="index"
                @click="itemClick(item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="tabs_info" id="tabs_info" v-html="initData.detail"></div>
        <div class="paramList" id="paramList">
          <div v-for="(item, index) in initData.param" :key="index">
            <el-collapse v-model="item.activeName" accordion>
              <el-collapse-item
                title="参数类型"
                class="paramList_title"
                name="1"
              >
                <div class="paramList_table">
                  <div class="table_content title">{{ item.paramKey }}</div>
                  <div class="table_content text">{{ item.paramValue }}</div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>

          <!-- <el-collapse v-model="activeName2" accordion>
            <el-collapse-item title="参数类型" class="paramList_title" name="1">
              <div class="paramList_table">
                <div class="table_content title">能量产品</div>
                <div class="table_content text">参数</div>
              </div>
            </el-collapse-item>
          </el-collapse>
          <el-collapse v-model="activeName3" accordion>
            <el-collapse-item title="参数类型" class="paramList_title" name="1">
              <div class="paramList_table">
                <div class="table_content title">能量产品</div>
                <div class="table_content text">参数</div>
              </div>
            </el-collapse-item>
          </el-collapse> -->
        </div>
        <div class="title" id="comment">
          <div class="comment_tite">优质点评</div>
        </div>

        <div
          class="comment"
          v-for="(item, index) in newcommentList"
          :key="index"
        >
          <div class="comment_header">
            <!-- <div class="usermsg"> -->

            <div class="username">
              <el-avatar :size="40" :src="item.memberIcon"></el-avatar>
              <span class="user">{{ item.description }}</span>
            </div>
            <div class="releaseTime">{{ item.createTime }}</div>
            <!-- </div> -->
          </div>

          <div class="comment_center">
            <div class="stars">
              <el-rate
                @click.native.stop
                v-model="item.score"
                disabled-void-icon-class="iconfont icon-start"
                :icon-classes="[
                  'iconfont icon-start',
                  'iconfont icon-start',
                  'iconfont icon-start',
                ]"
                disabled
                :colors="['#FF3355', '#FF3355', '#FF3355']"
              >
              </el-rate>
            </div>
            <div class="comment_center-r">
              {{ item.catName }}/{{ item.brandName }}/{{ item.apparatusType }}
            </div>
          </div>
          <div class="comment_text">
            {{ item.description }}
          </div>
          <div class="comment_img">
            <div v-for="(i, index) in item.file" :key="index">
              <img :src="i" alt="" />
            </div>
          </div>
          <div class="comment_count">
            <el-button
              :class="{ click: item.commentIsClick == 1 }"
              type="text"
              icon="iconfont icon-pinglundianzan"
              @click="isLikes(item.commentPros, 1, item.commentId)"
              >{{ item.commentPros || 0 }}</el-button
            >
            <el-button
              :class="{ click: item.commentIsClick == 2 }"
              type="text"
              icon="iconfont icon-pinglundianzantubiao"
              @click="isLikes(item.commentCons, 2, item.commentId)"
              >{{ item.commentCons || 0 }}</el-button
            >
          </div>
        </div>
        <div class="list" id="list">
          <content-title
            :first="'器械推荐'"
            :scende="'查看更多'"
            :to="'/instrumentlib/'"
            :border="true"
            :color="true"
          ></content-title>
          <release-layout
            :data="list"
            :row="4"
            :id="'layout2'"
            :type="'score'"
            :to="path"
          ></release-layout>
        </div>
      </div>
      <div class="info_content-slide">
        <div class="complaint__r">
          <div class="line"></div>
          <div class="topinfo__r_contanier">
            <div class="topinfo__r_header">
              <span>当前器械分类榜单</span>
            </div>
            <div class="topinfo__r_content">
              <div v-for="(item, index) in topList" :key="index">
                <div class="content-img top1" v-if="index == 0">
                  <div
                    class="box"
                    @click.stop="
                      toPath(
                        '/instrumentlib/info',
                        item.apparatusId,
                        item.apparatusCatId
                      )
                    "
                  >
                    <img :src="item.apparatusFileId" alt="" />
                    <div
                      class="content-title top1"
                      @click.stop="toPath('/instrumentlib/contrast', 1)"
                    >
                      {{ item.apparatusType }}
                    </div>
                    <span class="count"> {{ item.apparatusScore }}分 </span>
                    <div class="imgTitle">
                      <img src="../assets/t1.png" alt="" />
                    </div>
                    <div class="content-count">
                      <el-button
                        round
                        @click.stop="toPath('/instrumentlib/contrast', 1)"
                      >
                        加入对比
                      </el-button>
                    </div>
                  </div>
                </div>
                <div class="content-img" v-if="index != 0">
                  <div
                    class="box2"
                    @click.stop="
                      toPath(
                        '/instrumentlib/info',
                        item.apparatusId,
                        item.apparatusCatId
                      )
                    "
                  >
                    <div class="box2-l">
                      <img :src="item.apparatusFileId" alt="" />
                      <span class="count"> {{ item.apparatusScore }}分 </span>
                      <div class="imgTitle">
                        <img v-if="index == 1" src="../assets/t2.png" alt="" />
                        <img v-if="index == 2" src="../assets/t3.png" alt="" />
                      </div>
                    </div>

                    <div class="content-title2">
                      {{ item.apparatusName }}
                    </div>

                    <div class="content-count">
                      <el-button
                        round
                        @click.stop="toPath('/instrumentlib/contrast', 1)"
                      >
                        加入对比
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="topinfo__r_content">
              <div class="content-box"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import contentTitle from "../components/title.vue";
import releaseLayout from "../components/releaseLayout.vue";
import lib from "../api/lib";
import api from "../api/api";
export default {
  data() {
    return {
      activeName1: "",
      activeName2: "",
      activeName3: "",
      list: [],
      initData: {},
      topList: [],
      commentList: [],
      menus: [
        {
          name: "器械详情",
          el: "tabs_info",
          isClick: true,
        },
        {
          name: "参数列表",
          el: "paramList",
        },
        {
          name: "点评",
          el: "comment",
        },
        {
          name: "推荐",
          el: "list",
        },
      ],
      path: {
        url: "/instrumentlib/info",
      },
    };
  },
  computed: {
    newcommentList: {
      get() {
        for (const i in this.commentList) {
          let data = this.commentList[i];
          let dateTimeStamp = new Date(data.createTime).getTime();
          let minute = 1000 * 60;
          let hour = minute * 60;
          let day = hour * 24;

          let month = day * 30;
          let year = month * 12;
          let now = new Date().getTime();
          let diffValue = now - dateTimeStamp;
          let result = "";
          if (diffValue < 0) {
            return;
          }
          let monthC = diffValue / month;
          let weekC = diffValue / (7 * day);
          let dayC = diffValue / day;
          let hourC = diffValue / hour;
          let minC = diffValue / minute;
          let yearC = diffValue / year;
          if (yearC >= 1) {
            return "" + parseInt(yearC) + "年前";
          }
          if (monthC >= 1) {
            result = "" + parseInt(monthC) + "月前";
          } else if (weekC >= 1) {
            result = "" + parseInt(weekC) + "周前";
          } else if (dayC >= 1) {
            result = "" + parseInt(dayC) + "天前";
          } else if (hourC >= 1) {
            result = "" + parseInt(hourC) + "小时前";
          } else if (minC >= 1) {
            result = "" + parseInt(minC) + "分钟前";
          } else {
            result = "刚刚";
          }
          data.createTime = result;
          data.score = parseFloat(data.score).toFixed(1) / 2;
        }

        return this.commentList;
      },
      set() {},
    },
    starClass() {
      //stars
      let result = [];
      for (const i in this.commentList) {
        let data = this.commentList[i];

        let score = Math.floor(3.5 * 2) / 2;
        let starhalf = score % 1 != 0 ? true : false;
        let fullstar = Math.floor(score);
        for (let c = 0; c < fullstar; c++) {
          //放全星
          result.push("starOn");
        }
        if (starhalf) {
          //放半星
          result.push("starHalf");
        }
        if (5 - fullstar != 0) {
          for (let c = 0; c < 5 - fullstar - 1; c++) {
            result.push("starOff");
          }
        }
      }
      return result;
    },
  },
  components: {
    contentTitle,
    releaseLayout,
  },
  created() {
    this.infoApparatus();
    this.commentApparatus();
    this.indexTopApparatus({
      type: "recommend",
      catId: this.$route.params.catId,
    });
    this.indexTopApparatusT({ type: "score", catId: this.$route.params.catId });
  },
  watch: {
    $route(to, from) {
      this.infoApparatus();
      this.commentApparatus();
      this.indexTopApparatus({
        type: "recommend",
        catId: this.$route.params.catId,
      });
      this.indexTopApparatusT({
        type: "score",
        catId: this.$route.params.catId,
      });
    },
  },
  methods: {
    itemClick(item) {
      this.menus.forEach((item) => {
        item.isClick = false;
      });
      item.isClick = true;
      this.menus.splice(this.menus.length);
      this.jump(item.el);
    },
    jump(el) {
      let ele = `#${el}`;
      document.documentElement.scrollTop =
        document.querySelector(ele).offsetTop - 170;
    },
    toPath(path, id, catId) {
      let path1 = id ? `/${id}` : "";
      let path2 = catId ? `/${catId}` : "";
      this.$router.push(`${path}${path1}${path2}`);
    },
    infoApparatus() {
      let data = {
        id: this.$route.params.id,
      };
      lib.infoApparatus(data).then((res) => {
        let data = res.data.data;

        for (const i in data.param) {
          data.param[i].activeName = "";
        }

        if (!parseFloat(data.apparatusScore)) {
          data.apparatusScore = 0;
        } else {
          data.apparatusScore = parseFloat(data.apparatusScore).toFixed(1);
        }
        this.initData = res.data.data;
      });
    },

    commentApparatus() {
      let data = {
        apparatusId: this.$route.params.id,
        page: 1,
      };
      lib.commentApparatus(data).then((res) => {
        let data = res.data.data.data;
        this.commentList = data;
      });
    },
    indexTopApparatus(data) {
      api.indexTopApparatus(data).then((res) => {
        let newList = [];
        let list = res.data.data.data;
        list.forEach((item) => {
          if (!parseFloat(item.apparatusScore)) {
            item.apparatusScore = 0;
          } else {
            item.apparatusScore = parseFloat(item.apparatusScore).toFixed(1);
          }
          let data = {
            id: item.apparatusId,
            catId: item.apparatusCatId,
            url: item.apparatusFileId,
            newsTitle: item.apparatusName,
            manufactor: item.firmName,
            score: item.apparatusScore,
          };
          newList.push(data);
        });
        this.list = newList;
      });
    },
    indexTopApparatusT(data) {
      api.indexTopApparatus(data).then((res) => {
        let list = res.data.data.data;
        list.forEach(item=>{
          if (!parseFloat(item.apparatusScore)) {
            item.apparatusScore = 0;
          } else {
            item.apparatusScore = parseFloat(item.apparatusScore).toFixed(1);
          }
        })
        this.topList = list;
      });
    },
    to(data, type) {
      this.$router.push(`${data}/${type}`);
    },
    isLikes(commentCount, islike, id) {
      let data = {
        type: "comment",
        operate: islike == 1 ? "pros" : "cons",
        id,
      };

      api.operate(data).then((res) => {
        let result = res.data.data;
        this.commentList.forEach((item) => {
          if (item.commentId == result.commentId) {
            item.commentPros = result.commentPros;
            item.commentCons = result.commentCons;
          }
        });
      });
    },
  },
};
</script>
<style lang="scss">
.content-img {
  &.top1 {
    .content-count {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 13px;
      .el-button {
        background: transparent;
        font-size: 19px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        border: 1px solid #ffffff;
      }
    }
  }
}
.comment_center{
  .stars{
    .el-rate__item{
      i{
        font-size: 27px;
      }
      
      .el-rate__decimal{
        font-size: 27px;
      }
    }
  }
}
.el-collapse-item.paramList_title {
  .el-collapse-item__header {
    font-size: 21px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    border-bottom-color: #d2d4d6;
  }
  .el-collapse-item__wrap {
    border-bottom-color: transparent;
  }
}
.comment_count {
  .el-button {
    color: #bac0cf;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    &.click {
      color: #ff3355;
    }
  }
}
.box2 {
  .el-button {
    padding: 6px 8px;
    color: #0394ff;
    border-color: #0394ff;
  }
}
.button {
  .el-button {
    width: 133px;
    height: 47px;
    font-size: 21px;
    font-family: Pridi-Regular, Pridi;
    font-weight: 400;
    border-radius: 41px 41px 41px 41px;
    &:nth-child(1) {
      box-shadow: 0px 11px 25px 1px
        rgba(2.9999998211860657, 147.9999303817749, 255, 0.33000001311302185);
      margin-right: 20px;
    }
    &:nth-child(2) {
      color: #0394ff;
      border-color: #0394ff;
    }
  }
}
.paramList {
  .el-collapse {
    border-top: none;
  }
}
.tabs_info {
  p {
    font-size: 21px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 32px;
    letter-spacing: 1px;
    margin: 20px 0;
  }
  img {
    border-radius: 7px;
  }
}
</style>
<style lang="scss" scoped>
.info {
  .info_banner {
    // height: 645px;
    position: relative;
    background: linear-gradient(
      180deg,
      #1d1c59 0%,
      rgba(155, 112, 30, 0.5099999904632568) 100%
    );
    border-radius: 7px;

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
    .imgBox {
      border-radius: 7px;
      overflow: hidden;
      font-size: 0;
      img {
        transition: all 0.5s;
        width: 100%;
        border-radius: 7px;
      }
    }

    .banner_text {
      top: 0;
      width: 245px;
      height: 100%;
      position: absolute;
      left: 0;
      .mask {
        width: 100%;
        height: 100%;
        background: #384148;
        opacity: 0.5;
        border-radius: 7px 0 0 7px;
      }
      .textCntanier {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center;
        .title {
          font-size: 35px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
        }
        .text {
          font-size: 32px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #ffffff;
          width: 68px;
          text-align: left;
          margin: 0 auto;
        }
        .box {
          width: 133px;
          padding: 12px 27px;
          border: 1px solid #ffffff;
          margin: 0 auto;
          font-size: 27px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .floatbanner {
      width: 632px;
      // height: 304px;
      padding: 27px 35px;
      position: absolute;
      bottom: -53px;
      left: 50%;
      transform: translateX(-50%);
      background: #ffffff;
      box-shadow: 0px 5px 25px 1px
        rgba(2.9999998211860657, 147.9999303817749, 255, 0.18000000715255737);
      .floatTile {
        font-size: 27px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-bottom: 43px;
      }
      .floatTable {
        display: grid;
        padding: 0 35px;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto auto auto;
        .tabletitle {
          &.title {
            font-size: 19px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            padding: 13.5px 0;
            border-right: 1px solid #f0f0f0;
          }
          &.col {
            grid-row-start: span 6;
            .score {
              height: 100%;
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              .score_num {
                font-size: 48px;
                font-family: Helvetica-Oblique, Helvetica;
                font-weight: normal;
                color: #ff5c5c;
              }
              .score_text {
                font-size: 19px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #999999;
              }
            }
          }
        }
      }
      .button {
        display: flex;
        margin-top: 25px;
        justify-content: center;
      }
    }
  }

  .info_content {
    margin-top: 121px;
    display: flex;
    justify-content: space-between;
    .info_content-tabs {
      width: 797px;
      border-radius: 7px 7px 7px 7px;
      border-color: #e9e9e9;
      background: #ffffff;
      .info_content-menus {
        padding: 27px 27px 0px 27px;
        .border {
          border-bottom: 1px solid #d2d4d6;
        }
        .menus {
          display: flex;
          .item {
            font-size: 21px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #999999;
            padding: 0 20px 16px 20px;
            cursor: pointer;
            position: relative;
            &:first-child {
              padding: 0px 20px 16px 0;
            }
            &.click {
              font-size: 21px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #0394ff;
              &::after {
                display: block;
                content: " ";
                width: 48px;
                height: 8px;
                background: #0394ff;
                position: absolute;
                bottom: 0;
              }
            }
          }
        }
      }
      .tabs_info {
        padding: 0 27px;
      }
      .paramList {
        padding: 0 25px;
        background: #ffffff;
        .paramList_title {
        }
        .paramList_table {
          width: 100%;
          display: grid;
          grid-template-columns: 146px auto;
          grid-template-rows: auto;
          margin-top: 27px;
          .table_content {
            font-size: 19px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            text-align: center;
            padding: 13px 0;
            &.title {
              color: #333333;
              background: #fbfbfb;
              border: 1px solid #e9e9e9;
            }
            &.text {
              color: #666666;
              border: 1px solid #e9e9e9;
              margin-left: -1px;
            }
          }
          &:hover {
            box-shadow: 0px 5px 21px 1px
              rgba(
                11.404168456792831,
                73.7658217549324,
                119.00000050663948,
                0.17000000178813934
              );
          }
        }
      }
      .title {
        padding: 27px;
        background: #ffffff;
        .comment_tite {
          padding: 13px 0;
          font-size: 21px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 25px;
          border-bottom: 1px solid #d2d4d6;
        }
      }

      .comment {
        padding: 27px;
        background: #ffffff;

        .comment_header {
          margin-top: 27px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .username {
            display: flex;
            align-items: center;
            .user {
              margin-left: 10px;
            }
          }
          .releaseTime {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #bac0cf;
          }
        }
        .comment_center {
          display: grid;
          grid-template-columns: 200px auto;
          grid-template-rows: auto;
          margin-top: 10px;
          .comment_center-r {
            font-size: 19px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
          }
          .stars {
            .starOn {
              color: #ff3355;
            }
            .starOff {
              color: #dbdbdb;
            }
            .starHalf {
              color: #ff3355;
            }
            i {
              font-size: 20px;
            }
            img {
              height: 20px;
            }
          }
        }
        .comment_text {
          margin-top: 13px;
          height: 59px;
          font-size: 21px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        .comment_img {
          display: flex;
          img {
            min-width: 155px;
            min-height: 155px;
            border-radius: 7px;
          }
        }
        .comment_count {
          text-align: right;
        }
      }
      .list {
        background: #ffffff;
        padding: 27px;
      }
    }
    .info_content-slide {
      .complaint__r {
        width: 328px;
        border-radius: 7px;
        // border-top: 5px solid #0394FF;

        .topinfo__r_contanier {
          border: 1px solid #e9e9e9;
        }
        .line {
          height: 5px;
          background: #0394ff;
          border-radius: 7px 7px 0 0;
        }
        .topinfo__r_header {
          padding: 10px 19px;
          span {
            padding: 5px 0;
            border-bottom: 3px solid #0092ff;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #0394ff;
          }
        }
        .topinfo__r_content {
          .content-img {
            padding: 25px 19px;
            &.top1 {
              position: relative;
              .count {
                padding: 4px 5px;
                position: absolute;
                right: 13px;
                top: 13px;
                font-size: 15px;
                border-radius: 23px;

                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
              }
            }

            .box {
              position: relative;
              img {
                width: 100%;
                border-radius: 7px 7px 0 0;
              }
              .imgTitle {
                position: absolute;
                width: 37px;
                left: -5px;
                top: -10.5px;
              }
            }
            .box2 {
              display: grid;
              grid-template-columns: 50% 50%;
              grid-template-rows: auto auto;
              grid-column-gap: 15px;
              // grid-row-gap: 15px;

              .box2-l {
                position: relative;
                grid-row-start: 1;
                grid-row-end: 3;
                min-height: 88px;
                img {
                  width: 100%;
                  border-radius: 7px;
                }
                .imgTitle {
                  position: absolute;
                  width: 37px;
                  left: -5px;
                  top: -10.5px;
                }
                .count {
                  padding: 4px 5px;
                  position: absolute;
                  top: 5px;
                  right: 5px;
                  font-size: 15px;
                  border-radius: 23px;
                  // border: 1px solid #ffffff;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #ffffff;
                }
              }

              .content-title2 {
                font-size: 17px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
              }
              .content-count {
                display: flex;
                align-items: flex-end;
                font-size: 16px;
                font-family: Helvetica-Regular, Helvetica;
                font-weight: 400;
                color: #ff5c5c;
              }
            }
          }

          .content-title {
            &.top1 {
              //   width: 217px;
              position: absolute;
              padding: 13px 21px;
              top: 50%;

              left: 50%;
              transform: translate(-50%, -50%);

              font-size: 19px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              border: 1px solid #ddd;
              border-image: linear-gradient(
                  180deg,
                  rgba(255, 255, 255, 1),
                  rgba(255, 255, 255, 0)
                )
                1 1;
            }
          }
        }
      }
    }
  }
}
</style>